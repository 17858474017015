import { Injectable, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

export const ThemeManagerThemes = ['dark', 'light'] as const;
export type ThemeManagerTheme = (typeof ThemeManagerThemes)[number];
export const ThemeManagerCls = 'theme-manager';
export const ThemeManagerAnimateCls = 'theme-manager--animate';
export const ThemeManagerAnimateDelay = 500;
export const ThemeManagerLocalstorageKey = 'theme-manager--current';

@Injectable({
  providedIn: 'root',
})
export class ThemeManagerService {
  currentTheme = signal<ThemeManagerTheme>('light');

  constructor(readonly lss: LocalStorageService) {}

  applyDefault() {
    const lsTheme = this.lss.get<ThemeManagerTheme>(ThemeManagerLocalstorageKey);
    if (lsTheme && ThemeManagerThemes.includes(lsTheme)) {
      // valid entry in localStorage to apply
      return this.applyTheme(lsTheme);
    }

    // Default to light theme
    let themeToApply: ThemeManagerTheme = 'light';

    // Check if user prefers dark mode
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    if (mql.matches === true) {
      themeToApply = 'dark';
    }
    return this.applyTheme(themeToApply);
  }

  toggle(animate = false): void {
    const lsTheme = this.lss.get<ThemeManagerTheme>(ThemeManagerLocalstorageKey) || 'light';
    const newTheme = ThemeManagerThemes[(ThemeManagerThemes.indexOf(lsTheme) + 1) % ThemeManagerThemes.length];
    this.applyTheme(newTheme, animate);
  }

  applyTheme(theme: ThemeManagerTheme, animate = false, animateDelay = ThemeManagerAnimateDelay): void {
    if (!animate) {
      this._replaceThemeInHead(theme);
      return;
    }
    document.body.classList.add(ThemeManagerAnimateCls);
    setTimeout(() => this._replaceThemeInHead(theme), animateDelay);
    setTimeout(() => document.body.classList.remove(ThemeManagerAnimateCls), animateDelay * 2);
  }

  _replaceThemeInHead(theme: ThemeManagerTheme): HTMLLinkElement {
    // Remove existing themes
    // There should be only one, but you never know
    const existingThemes: NodeList = document.head.querySelectorAll(`link[rel="stylesheet"].${ThemeManagerCls}`);
    existingThemes.forEach((el) => document.head.removeChild(el));

    const themeLinkEl: HTMLLinkElement = document.createElement('link');
    themeLinkEl.setAttribute('rel', 'stylesheet');
    themeLinkEl.setAttribute('href', `mat-theme-${theme}.css`);
    themeLinkEl.classList.add(ThemeManagerCls, [ThemeManagerCls, theme].join('-'));
    document.head.appendChild(themeLinkEl);
    this.lss.set(ThemeManagerLocalstorageKey, theme);
    this.currentTheme.set(theme);
    return themeLinkEl;
  }
}
