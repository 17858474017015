import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  static readonly themeKey = 'theme-current-name';

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  get<T = string>(key: string): T | null {
    return (localStorage.getItem(key) as T) || null;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}
